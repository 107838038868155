import { DatePipe } from '@angular/common';
import { AfterViewChecked, Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, LoaderService } from '@app/_services';
import { delay } from 'rxjs/operators';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { TranslateService } from '@ngx-translate/core';

@Component({ selector: 'app', templateUrl: 'app.component.html' })

export class AppComponent implements OnInit, AfterViewChecked {
  locations: string;
  title = 'Blixen Tours';
  loading: boolean = false;
  selectedLocale = 'fr-FR'; // Default locale
  formattedDate: any;
  accountCode: string;
  dashboardDetails: any;
  itineraryNo: any;
  userid: string;
  accountcode: string;
  itineraryno: any;
  user: any;

  constructor(private route: ActivatedRoute, private router: Router, private translate: TranslateService, private loaderService: LoaderService, private dummyService: AccountService, private datePipe: DatePipe, @Inject(LOCALE_ID) private locale: string) {
    this.updateDate(); // Initial date format based on default locale
  }

  slides = [
    { id: "1", img: 'assets/images/1.jpg', text: 'Image 1 Description' },
    { id: "2", img: 'assets/images/2.png', text: 'Image 2 Description' },
    { id: "3", img: 'assets/images/3.png', text: 'Image 3 Description' },
    { id: "4", img: 'assets/images/4.png', text: 'Image 3 Description' },
    { id: "5", img: 'assets/images/5.png', text: 'Image 3 Description' }
  ];

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 4
      },
      400: {
        items: 4
      },
      740: {
        items: 4
      },
      940: {
        items: 4
      }
    },
    nav: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true
  };

  updateDate() {
    const currentDate = new Date();
    const formattedDate = this.datePipe.transform(currentDate, 'full', this.selectedLocale);
    this.formattedDate = formattedDate;
  }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem('user')!) || [];
    const branchCode = this.user['branchCode'];
    if (branchCode == 'US') {
      this.translate.use('en');
    } else if (branchCode == 'NO') {
      this.translate.use('nb');
    } else if (branchCode == 'DK') {
      this.translate.use('de');
    } else {
      this.translate.use('de');
    }
  }

  /************ Dictionary changes ************/
  ngAfterViewChecked(): void {
    // Call function to replace the data with dictionary
    this.replaceTextWithDictionary(this.getDictionary());
  }

  getDictionary(): { [key: string]: string } {
    return JSON.parse(sessionStorage.getItem('dictionary') || '{}');
  }

  replaceTextWithDictionary(dictionary: { [key: string]: string }) {
    const walker = document.createTreeWalker(
      document.body,
      NodeFilter.SHOW_TEXT,
      null
    );
    let node: Text | null;

    while ((node = walker.nextNode() as Text | null)) {
      for (const [key, value] of Object.entries(dictionary)) {
        if (value !== undefined && value !== null && value !== '') {
          const regex = new RegExp(`\\b${key}\\b`, 'gi'); // 'gi' for case-insensitive matching
          node.textContent = node.textContent?.replace(regex, (match) => {
            if (match === match.toUpperCase()) {
              return value.toUpperCase(); // Replace with uppercase
            } else if (match === match.toLowerCase()) {
              return value.toLowerCase(); // Replace with lowercase
            } else if (match[0] === match[0].toUpperCase()) {
              return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(); // Replace with capitalized (Title Case)
            } else {
              return value; // Default to the given case
            }
          }) || '';
        }
      }
    }
  }

  // this is for the service response innter html data
  private replaceResponseWithDictionary(content: string, dictionary: { [key: string]: string }): string {
    for (const [key, value] of Object.entries(dictionary)) {
      const regex = new RegExp(`\\b${key}\\b`, 'g');
      content = content?.replace(regex, value);
    }
    return content;
  }

  /************ Dictionary changes ************/


  GetLocations() {
    this.locations = "Getting Locations";
    this.dummyService.fetchData().subscribe((res: any) => {
      this.locations = "Got Locations";
      // console.log(res);
    });
  }
}